<template>
  <div>
    <!-- 头部搜索 -->
    <div class="TBclis" v-if="1 == 2">
      <div style="width: 14%; margin: 0 10px" >
        <el-input
          v-model="searchbox"
          class="w-50 m-2"
          placeholder="输入名字/手机号"
        >
          <template #suffix>
            <el-icon class="el-input__icon" @click="Searchcli">
              <search />
            </el-icon>
          </template>
        </el-input>
      </div>
      <!-- <div style="width: 10%; margin: 0 10px">
        <el-select v-model="value" class="m-2" placeholder="选择">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>
      </div>-->
    </div>
    <!-- 中间内容 -->
    <div class="bdcli">
      <el-row>
        <el-col :span="6" v-for="(item, index) in list" :key="index">
          <div class="zy-bord">
            <div style="display: flex">
              <!-- 名字和年龄 -->
              <div class="zy-age">
                <div class="tobys">{{ item.patient_name }} {{item.patient.dict_sex_name}}</div>
                <div style="display: flex; margin-top: 5px">
                  <div style="margin-right: 10px">{{ item.patient.age }}岁</div>
                  <div>{{ item.nm_name }}</div>
                  <span>床位号：{{item.bed_num}}</span>
                </div>
              </div>
            </div>
            <!-- 下划线 -->
            <el-divider></el-divider>
            <!-- 底部内容区 -->
            <div style="display: flex">
              <span>透析类型：{{item.nm_dialyse_way}}</span>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <!-- 弹窗 -->
    <div>
      <el-dialog
        v-model="dialogTableVisible"
        title="透析计划 "
        draggable
        @close="closedialog"
      >
        <!-- 头部患者信息 -->
        <div class="uins">
          <div style="margin: 0 20px; font-size: 18px">{{ name }}</div>
          <div style="margin: 0 10px">{{ sex }}</div>
          <div style="margin: 0 10px">{{ age }}岁</div>
          <div style="margin: 0 10px">病案号：{{ case_no }}</div>
        </div>
        <!-- 周期部分 -->
        <div class="ZQclss">
          <!-- 左侧 -->
          <div class="life-clis">当前为第{{ week }}周，{{ is_single }}</div>
          <!-- 右侧 -->
          <div class="ZQclss_right">
            <el-tooltip
              class="box-item"
              effect="dark"
              :content="content"
              placement="top-start"
            >
              <div>
                <i class="fa fa-question-circle-o icou1"></i>
              </div>
            </el-tooltip>
            <div class="ZQclss_right_text">常规安排</div>
          </div>
        </div>
        <!-- 单周表格部分 -->
        <div class="tab">
          <!-- 左侧 -->
          <div class="teb-left">
            <div class="teb-left_text">班次</div>
            <div class="tab1" v-for="item in classeslist" :key="item.id">
              {{ item.name }}
            </div>
          </div>
          <!-- 右侧 -->
          <div style="width: 93%; text-align: center">
            <div class="Tobu">单周透析安排</div>
            <div class="RQclis">
              <div class="ZQlis" v-for="(items, index) in ZQlist" :key="index">
                {{ items.value }}
              </div>
            </div>

            <div class="BOclis">
              <div
                class="BOlis"
                v-for="(items, index) in morning"
                :key="index"
                @mouseenter="ZQweek(index)"
                @mouseleave="ZQleave"
                @contextmenu.prevent.stop="rightClick(index, '上午')"
                @click="leftClick"
              >
                <el-popover
                  :visible="contextmenuIndex === index ? true : false"
                  ref="popover"
                  :width="200"
                  trigger="contextmenu"
                >
                  <template #reference>
                    <div style="display: flex">
                      <div class="reference_text">
                        {{ items.dict_dialysis_type_name }}
                      </div>
                      <i
                        @click="EDdelete(items.id)"
                        class="fa fa-times-circle"
                        v-if="
                          weekIndex === index && items.dict_dialysis_type_name
                        "
                      ></i>
                    </div>
                  </template>
                  <div
                    @click="Dialysis_code(item.code, 1)"
                    class="tabcliss"
                    v-for="item in dictList[100000000]"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </div>
                </el-popover>
              </div>
            </div>
            <!-- 下午 -->
            <div class="BOclis">
              <div
                class="BOlis"
                v-for="(itemspro, indexpro) in afternoon"
                :key="indexpro"
                @mouseenter="afternoonweek(indexpro)"
                @mouseleave="afternoonleave"
                @contextmenu.prevent.stop="afternoonClick(indexpro, '下午')"
                @click="leftClick"
              >
                <el-popover
                  :visible="afternoonIndex === indexpro ? true : false"
                  ref="popover"
                  :width="200"
                  trigger="contextmenu"
                >
                  <template #reference>
                    <div style="display: flex">
                      <div class="reference_text">
                        {{ itemspro.dict_dialysis_type_name }}
                      </div>
                      <i
                        @click="EDdelete(itemspro.id)"
                        class="fa fa-times-circle"
                        v-if="
                          afternoonweekIndex === indexpro &&
                          itemspro.dict_dialysis_type_name
                        "
                      ></i>
                    </div>
                  </template>
                  <div
                    @click="Dialysis_code(item.code, 1)"
                    class="tabcliss"
                    v-for="item in dictList[100000000]"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </div>
                </el-popover>
              </div>
            </div>
            <!-- 晚上 -->
            <div class="BOclis">
              <div
                class="BOlis"
                v-for="(itemxs, indexs) in night"
                :key="indexs"
                @mouseenter="nightweek(indexs)"
                @mouseleave="nightleave"
                @contextmenu.prevent.stop="nightClick(indexs, '晚上')"
                @click="leftClick"
              >
                <el-popover
                  :visible="nightIndex === indexs ? true : false"
                  ref="popover"
                  :width="200"
                  trigger="contextmenu"
                >
                  <template #reference>
                    <div style="display: flex">
                      <div class="reference_text">
                        {{ itemxs.dict_dialysis_type_name }}
                      </div>
                      <i
                        @click="EDdelete(itemxs.id)"
                        class="fa fa-times-circle"
                        v-if="
                          nightweekIndex === indexs &&
                          itemxs.dict_dialysis_type_name
                        "
                      ></i>
                    </div>
                  </template>
                  <div
                    @click="Dialysis_code(item.code, 1)"
                    class="tabcliss"
                    v-for="item in dictList[100000000]"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </div>
                </el-popover>
              </div>
            </div>
          </div>
        </div>
        <!-- 双周表格部分 -->
        <div class="tab">
          <!-- 左侧 -->
          <div class="teb-left">
            <div class="teb-left_text">班次</div>
            <div class="tab1" v-for="item in classeslist" :key="item.id">
              {{ item.name }}
            </div>
          </div>
          <!-- 右侧 -->
          <div style="width: 93%; text-align: center">
            <div class="Tobu">双周透析安排</div>
            <div class="RQclis">
              <div class="ZQlis" v-for="(items, index) in ZQlist" :key="index">
                {{ items.value }}
              </div>
            </div>

            <div class="BOclis">
              <div
                class="BOlis"
                v-for="(double_items, double_index) in double_morning"
                :key="double_index"
                @mouseenter="double_week(double_index)"
                @mouseleave="double_leave"
                @contextmenu.prevent.stop="
                  double_rightClick(double_index, '上午')
                "
                @click="double_leftClick"
              >
                <el-popover
                  :visible="
                    double_contextmenuIndex === double_index ? true : false
                  "
                  ref="popover"
                  :width="200"
                  trigger="contextmenu"
                >
                  <template #reference>
                    <div style="display: flex">
                      <div class="reference_text">
                        {{ double_items.dict_dialysis_type_name }}
                      </div>
                      <i
                        @click="EDdelete(double_items.id)"
                        class="fa fa-times-circle"
                        v-if="
                          double_weekIndex === double_index &&
                          double_items.dict_dialysis_type_name
                        "
                      ></i>
                    </div>
                  </template>
                  <div
                    @click="Dialysis_code(item.code, 2)"
                    class="tabcliss"
                    v-for="item in dictList[100000000]"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </div>
                </el-popover>
              </div>
            </div>
            <!-- 下午 -->
            <div class="BOclis">
              <div
                class="BOlis"
                v-for="(double_itemspro, double_indexpro) in double_afternoon"
                :key="double_indexpro"
                @mouseenter="double_afternoonweek(double_indexpro)"
                @mouseleave="double_afternoonleave"
                @contextmenu.prevent.stop="
                  double_afternoonClick(double_indexpro, '下午')
                "
                @click="leftClick"
              >
                <el-popover
                  :visible="
                    double_afternoonIndex === double_indexpro ? true : false
                  "
                  ref="popover"
                  :width="200"
                  trigger="contextmenu"
                >
                  <template #reference>
                    <div style="display: flex">
                      <div class="reference_text">
                        {{ double_itemspro.dict_dialysis_type_name }}
                      </div>
                      <i
                        @click="EDdelete(double_itemspro.id)"
                        class="fa fa-times-circle"
                        v-if="
                          double_afternoonweekIndex === double_indexpro &&
                          double_itemspro.dict_dialysis_type_name
                        "
                      ></i>
                    </div>
                  </template>
                  <div
                    @click="Dialysis_code(item.code, 2)"
                    class="tabcliss"
                    v-for="item in dictList[100000000]"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </div>
                </el-popover>
              </div>
            </div>
            <!-- 晚上 -->
            <div class="BOclis">
              <div
                class="BOlis"
                v-for="(double_itemxs, double_indexs) in double_night"
                :key="double_indexs"
                @mouseenter="double_nightweek(double_indexs)"
                @mouseleave="double_nightleave"
                @contextmenu.prevent.stop="
                  double_nightClick(double_indexs, '晚上')
                "
                @click="leftClick"
              >
                <el-popover
                  :visible="double_nightIndex === double_indexs ? true : false"
                  ref="popover"
                  :width="200"
                  trigger="contextmenu"
                >
                  <template #reference>
                    <div style="display: flex">
                      <div class="reference_text">
                        {{ double_itemxs.dict_dialysis_type_name }}
                      </div>
                      <i
                        @click="EDdelete(double_itemxs.id)"
                        class="fa fa-times-circle"
                        v-if="
                          double_nightweekIndex === double_indexs &&
                          double_itemxs.dict_dialysis_type_name
                        "
                      ></i>
                    </div>
                  </template>
                  <div
                    @click="Dialysis_code(item.code, 2)"
                    class="tabcliss"
                    v-for="item in dictList[100000000]"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </div>
                </el-popover>
              </div>
            </div>
          </div>
        </div>

        <!-- 双周期部分 -->
        <div class="ZQclss">
          <!-- 左侧 -->
          <div class="life-clis">当前为第{{ week }}周，{{ is_single }}</div>
          <!-- 右侧 -->
          <div
            style="display: flex; justify-content: space-between; width: 224px"
          >
            <el-tooltip
              class="box-item"
              effect="dark"
              :content="contents"
              placement="top-start"
            >
              <div>
                <i class="fa fa-question-circle-o icou1"></i>
              </div>
            </el-tooltip>
            <div style="font-size: 14px; font-weight: 400; color: #3166ae">
              特殊安排
            </div>
            <div>
              <div class="btncs" @click="Tanchuang">
                <div class="icouis">
                  <el-icon>
                    <circle-plus />
                  </el-icon>
                </div>
                <div>新增</div>
              </div>
            </div>
          </div>
        </div>

        <!-- 表格 -->
        <div>
          <el-table
            :data="tableData"
            v-loading="loading"
            stripe
            style="min-width: 100%"
          >
            <el-table-column prop="week_index" label="星期" />
            <el-table-column prop="dict_code" label="班次" />
            <el-table-column prop="dict_dialysis_type_name" label="透析方式" />
            <el-table-column prop="week_count" label="循环周期" />
            <el-table-column prop="str" label="起始周" />
            <el-table-column label="操作">
              <template #default="scope">
                <div style="display: flex; justify-content: space-between">
                  <!-- 上 -->
                  <div class="Cartop" @click="order(scope.row.id, 'up')">
                    <el-icon>
                      <caret-top />
                    </el-icon>
                  </div>
                  <!-- 下 -->
                  <div class="Cartop" @click="order(scope.row.id, 'down')">
                    <el-icon>
                      <caret-bottom />
                    </el-icon>
                  </div>
                  <!-- 编辑 -->
                  <div
                    style="font-size: 15px; color: #3166ae; cursor: pointer"
                    @click="modification(scope.row)"
                  >
                    <el-icon>
                      <edit />
                    </el-icon>
                  </div>
                  <!-- 删除 -->
                  <el-popconfirm
                    title="是否确认删除！"
                    @confirm="EDdelete(scope.row.id)"
                  >
                    <template #reference>
                      <div
                        @mouseenter="enter"
                        @mouseleave="leave"
                        :class="[tobyt ? 'Edrcliss' : 'Edrclis']"
                      >
                        <div v-if="tobyt">删</div>
                        <div v-if="!tobyt">
                          <el-icon>
                            <delete />
                          </el-icon>
                        </div>
                      </div>
                    </template>
                  </el-popconfirm>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-dialog>
    </div>
    <!-- 新增弹窗 -->

    <div>
      <el-dialog
        v-model="dialogisible"
        title="新增特殊安排 "
        draggable
        :width="800"
      >
        <div class="cliss">当前为第{{ week }}周，{{ is_single }}</div>
        <div style="display: flex; margin: 15px 0">
          <!-- 五角星图标 -->
          <div style="color: rgba(255, 62, 62, 1); font-size: 10px">
            <el-icon>
              <star-filled />
            </el-icon>
          </div>
          <div style="margin: 3px 0; font-size: 14px">起始周：</div>
        </div>
        <!-- 选择框 -->
        <div>
          <el-select
            v-model="weekval"
            class="m-2"
            placeholder="请输入周次"
            @change="SelectWeek"
          >
            <el-option
              v-for="(items, indexs) in Weekstarting"
              :key="indexs"
              :label="items.weekname"
              :value="items.id"
            ></el-option>
          </el-select>
        </div>
        <!-- 选择星期 -->
        <div style="display: flex; margin: 15px 0">
          <!-- 五角星图标 -->
          <div style="color: rgba(255, 62, 62, 1); font-size: 10px">
            <el-icon>
              <star-filled />
            </el-icon>
          </div>
          <div style="margin: 3px 0; font-size: 14px">星期：</div>
        </div>
        <!-- 单选框 -->
        <div class="radion">
          <div style="width: 33%">
            <el-radio v-model="radio1" label="1" size="large">星期一</el-radio>
          </div>
          <div style="width: 33%; text-align: center">
            <el-radio v-model="radio1" label="2" size="large">星期二</el-radio>
          </div>
          <div style="width: 33%; text-align: right">
            <el-radio v-model="radio1" label="3" size="large">星期三</el-radio>
          </div>
          <div style="width: 33%">
            <el-radio v-model="radio1" label="4" size="large">星期四</el-radio>
          </div>
          <div style="width: 33%; text-align: center">
            <el-radio v-model="radio1" label="5" size="large">星期五</el-radio>
          </div>
          <div style="width: 33%; text-align: right">
            <el-radio v-model="radio1" label="6" size="large">星期六</el-radio>
          </div>
          <div style="width: 33%">
            <el-radio v-model="radio1" label="7" size="large">星期日</el-radio>
          </div>
        </div>
        <!-- 选择班次 -->
        <div style="display: flex; margin: 15px 0">
          <!-- 五角星图标 -->
          <div style="color: rgba(255, 62, 62, 1); font-size: 10px">
            <el-icon>
              <star-filled />
            </el-icon>
          </div>
          <div style="margin: 3px 0; font-size: 14px">班次：</div>
        </div>
        <!-- 单选框 -->
        <div class="radion">
          <div style="width: 33%">
            <el-radio v-model="radio2" label="上午" size="large">上午</el-radio>
          </div>
          <div style="width: 33%; text-align: center">
            <el-radio v-model="radio2" label="下午" size="large">下午</el-radio>
          </div>
          <div style="width: 33%; text-align: right">
            <el-radio v-model="radio2" label="晚上" size="large">晚上</el-radio>
          </div>
        </div>
        <!--透析方式 -->
        <div style="display: flex; margin: 15px 0">
          <!-- 五角星图标 -->
          <div style="color: rgba(255, 62, 62, 1); font-size: 10px">
            <el-icon>
              <star-filled />
            </el-icon>
          </div>
          <div style="margin: 3px 0; font-size: 14px">透析方式：</div>
        </div>
        <!-- 选择框 -->
        <div>
          <el-select
            v-model="Dialysis_way"
            class="m-2"
            placeholder="请选择透析方式"
            @change="Dialysi"
          >
            <el-option
              v-for="item in dictList[100000000]"
              :key="item.id"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </div>
        <!--透析方式 -->
        <div style="display: flex; margin: 15px 0">
          <!-- 五角星图标 -->
          <div style="color: rgba(255, 62, 62, 1); font-size: 10px">
            <el-icon>
              <star-filled />
            </el-icon>
          </div>
          <div style="margin: 3px 0; font-size: 14px">循环周期：</div>
        </div>
        <!-- 输入框 -->
        <div>
          <el-input v-model="week_count" placeholder="请输入周期数（正整数）" />
        </div>
        <!-- 底部文字区 -->
        <div class="textclis">
          <!-- 第一行 -->
          <div style="display: flex; height: 24px；">
            <div style="color: #3166ae; width: 70px; text-align: right">
              起始周：
            </div>
            <div>第一次使用该透析安排所在的周</div>
          </div>
          <!-- 第二行 -->
          <div style="display: flex; height: 24px; margin-top: 10px">
            <div style="color: #3166ae; width: 20%; text-align: right">
              循环周期：
            </div>
            <div>
              基于起始周往后推算，如起始周为49周,循环周期为1则表示每周(49,50,51,52...)都执行该安排；
              循环周期为2表示每两周(49,51,53,55...)执行一次；循环周期为3表示每三周(49,52,55,58...)都执行该安排。
            </div>
          </div>
        </div>
        <!-- 分割线 -->
        <el-divider />
        <!-- 底部按钮 -->
        <div class="botbtn">
          <el-button @click="ceolie">取消</el-button>
          <el-button type="primary" @click="addplan">保存</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  Search,
  CirclePlus,
  CaretTop,
  CaretBottom,
  Edit,
  Delete,
  StarFilled,
} from '@element-plus/icons-vue'
import service from '@/utils/request'
import { gitDictList } from '@/utils/tool'
import { ElMessage } from 'element-plus'

export default {
  components: {
    Search,
    CirclePlus,
    CaretTop,
    CaretBottom,
    Edit,
    Delete,
    StarFilled,
  },

  data() {
    return {
      weekval: '',
      radio1: '1',
      radio2: '上午',
      dictList: {},
      errorHandler: true,
      dialogTableVisible: false,
      options: [
        {
          value: 'Option1',
          label: 'Option1',
        },
        {
          value: 'Option2',
          label: 'Option2',
        },
        {
          value: 'Option3',
          label: 'Option3',
        },
        {
          value: 'Option4',
          label: 'Option4',
        },
        {
          value: 'Option5',
          label: 'Option5',
        },
      ],
      ZQlist: [
        {
          value: '周一',
          id: 1,
        },
        {
          value: '周二',
          id: 2,
        },
        {
          value: '周三',
          id: 3,
        },
        {
          value: '周四',
          id: 4,
        },
        {
          value: '周五',
          id: 5,
        },
        {
          value: '周六',
          id: 6,
        },
        {
          value: '周日',
          id: 7,
        },
      ],
      tableData: [],
      list: [],
      content:
        '常规安排：根据系统提供的当前周数做参考，给患者设定单周和双周透析安排，设置完成即可在预约排班-->自动排班使用该安排',
      contents:
        '特殊安排：是指常规安排不能完全满足患者透析安排要求（如：每三周的周五下午做HDF）,我们可以设定特殊安排，特殊安排的优先级高于常规安排,如果有多个特殊安排可以在操作列里的“三角符”调整优先级顺序，列表越靠后优先级越高',
      tobyt: false,
      dialogisible: false,
      searchbox: '',
      name: '',
      age: '',
      sex: '',
      case_no: '',
      week: '',
      is_single: '',
      classeslist: [],
      weekIndex: '',
      weekbody: '',
      Dialysis_way: '',
      week_id: '',
      patient_id: '',
      week_count: null,
      opins: true,
      uid: '',
      visible: false,
      contextmenuIndex: '',
      loading: true,
      afternoon: [],
      morning: [],
      night: [],
      nightIndex: '',
      nightweekIndex: '',
      afternoonIndex: '',
      afternoonweekIndex: '',
      double_afternoon: [],
      double_morning: [],
      double_night: [],
      double_contextmenuIndex: '',
      double_weekIndex: '',
      double_afternoonIndex: '',
      double_afternoonweekIndex: '',
      double_nightweekIndex: '',
      double_nightIndex: '',
    }
  },
  created() {
    // 获取患者列表
    this.monitor()
    // 获取字典
    this.getDict()
  },
  methods: {
    // 卡片点击
    bodycl(val) {
      this.dialogTableVisible = true
      console.log(val)
      this.name = val.name
      this.age = val.age
      this.sex = val.dict_sex_name
      this.case_no = val.case_no
      this.patient_id = val.patient_id
      // 获取当前周期
      this.period()
      // 获取排版配置
      this.composing(val.id)
      // 获取起始周
      this.week_list()
    },
    // 获取周期
    async period() {
      let res = await service.post('/api/patient_Schedule/get_current_week')
      if (res.code === 0) {
        this.week = res.data.week.number
        this.is_single = res.data.is_single
        this.week_id = res.data.week.id
      }
    },
    // 新增特殊安排取消
    ceolie() {
      this.dialogisible = false
      this.weekval = ''
      this.radio1 = '1'
      this.radio2 = '上午'
      this.Dialysis_way = ''
      this.week_count = null
    },
    // 起始周ID
    SelectWeek(id) {
      this.week_id = id
    },
    // 获取排版配置和透析计划表
    async composing(id) {
      this.loading = true
      this.patient_id = id
      let res = await service.post('/api/patient_Schedule/get_class_conf')
      if (res.code === 0) {
        this.classeslist = res.data.class_conf
        // 获取透析计划表
        let val = await service.post(
          '/api/patient_Schedule/get_patient_schedule_list',
          {
            patient_id: id,
          }
        )
        if (val.code === 0) {
          // 底部表格数据
          this.tableData = val.data.list.other
          // 单周表格数据
          this.morning = val.data.list.single_week.morning
          this.night = val.data.list.single_week.night
          this.afternoon = val.data.list.single_week.afternoon
          // 双周表格数据
          this.double_morning = val.data.list.double_week.morning
          this.double_night = val.data.list.double_week.night
          this.double_afternoon = val.data.list.double_week.afternoon
          console.log(this.night)
          this.loading = false
        } else {
          this.tableData = []
        }
        console.log(this.tableData)
      }
    },
    // 透析方式code
    Dialysi(code) {
      this.Dialysis_way = code
    },
    // 修改
    modification(val) {
      this.opins = false
      this.dialogisible = true
      this.uid = val.id
      this.week_count = val.week_count
      this.radio1 = String(val.week_index)
      this.radio2 = val.dict_code
      this.patient_id = val.patient_id
      this.Dialysis_way = val.dict_dialysis_type
      this.weekval = val.str
      this.week_id = val.week_id
      console.log(val)
    },
    // 移入
    enter() {
      this.tobyt = true
    },
    // 移出
    leave() {
      this.tobyt = false
    },
    // 新增弹窗
    async Tanchuang() {
      this.dialogisible = true
    },
    // 获得起始周
    async week_list() {
      let res = await service.post('/api/patient_Schedule/get_start_week_list')
      res.data.records.forEach((v) => {
        v.weekname = v.number + '周' + '(' + v.start_time + v.end_time + ')'
      })

      this.Weekstarting = res.data.records
      console.log(this.Weekstarting)
    },
    // 搜索
    Searchcli() {
      this.monitor()
    },
    // 获取患者列表
    async monitor() {
      let res = await service.post('/api/patient/get_plan_patient_list', {
        keyword: this.searchbox,
      })
      if (res.code === 0) {
        this.list = res.data
      }
    },
    // 新增
    async addplan() {
      if (this.week_count <= 0) {
        ElMessage({
          message: '循环周期不得小于1',
          type: 'error',
        })
        return
      }
      if (this.opins) {
        let data = {
          week_id: this.week_id,
          patient_id: this.patient_id,
          schedule_type: 3,
          dict_code: this.radio2,
          dict_dialysis_type: this.Dialysis_way,
          week_index: this.radio1,
          week_count: this.week_count,
        }
        let res = await service.post(
          '/api/patient_Schedule/create_patient_schedule',
          data
        )
        if (res.code === 0) {
          this.$message({
            message: '保存成功',
            type: 'success',
          })
          this.dialogisible = false
          this.weekval = ''
          this.radio1 = '1'
          this.radio2 = '上午'
          this.Dialysis_way = ''
          this.week_count = null
          this.composing(this.patient_id)
        }
      } else {
        let data = {
          id: this.uid,
          week_id: this.week_id,
          patient_id: this.patient_id,
          schedule_type: 3,
          dict_code: this.radio2,
          dict_dialysis_type: this.Dialysis_way,
          week_index: this.radio1,
          week_count: this.week_count,
        }
        let res = await service.post(
          '/api/patient_Schedule/edit_patient_schedule',
          data
        )
        if (res.code === 0) {
          this.$message({
            message: '修改成功',
            type: 'success',
          })
          this.dialogisible = false
          this.composing(this.patient_id)
        }
      }
    },
    // 删除
    async EDdelete(id) {
      let res = await service.post(
        '/api/patient_Schedule/delete_patient_schedule',
        { id }
      )
      if (res.code === 0) {
        this.$message({
          message: '删除成功',
          type: 'success',
        })
        this.composing(this.patient_id)
      }
    },
    //单周上午移入
    ZQweek(index) {
      this.weekIndex = index
    },
    // 单周上午移除
    ZQleave() {
      this.weekIndex = -1
    },
    //双周上午移入
    double_week(index) {
      this.double_weekIndex = index
    },
    // 双周上午移除
    double_leave() {
      this.double_weekIndex = -1
    },
    //单周晚上移入
    nightweek(index) {
      this.nightweekIndex = index
      console.log(index)
    },
    // 单周晚上移除
    nightleave() {
      this.nightweekIndex = -1
    },
    //双周晚上移入
    double_nightweek(index) {
      this.double_nightweekIndex = index
      console.log(index)
    },
    // 双周晚上移除
    double_nightleave() {
      this.double_nightweekIndex = -1
    },
    //单周下午移入
    afternoonweek(index) {
      console.log(index)
      this.afternoonweekIndex = index
    },
    // 单周下午移除
    afternoonleave() {
      this.afternoonweekIndex = -1
    },
    //双周下午移入
    double_afternoonweek(index) {
      this.double_afternoonweekIndex = index
    },
    // 双周下午移除
    double_afternoonleave() {
      this.double_afternoonweekIndex = -1
    },
    // 获取字典数据
    async getDict() {
      this.dictList = await gitDictList(['100000000'])
    },
    // 顺序操作
    async order(id, val) {
      this.loading = true
      let data = {
        id: id,
        type: val,
      }
      let res = await service.post(
        '/api/patient_Schedule/sort_patient_schedule',
        data
      )
      if (res.code === 0) {
        this.composing(this.patient_id)
        this.loading = false
      }
    },
    // 单周上午右键新增
    rightClick(index, val) {
      this.contextmenuIndex = index
      this.dict_code = val
      this.week_index = Number(index)
    },
    // 双周上午右键新增
    double_rightClick(index, val) {
      this.double_contextmenuIndex = index
      this.dict_code = val
      this.week_index = Number(index)
    },
    // 左键点击取消
    leftClick() {
      this.contextmenuIndex = ''
      this.nightIndex = ''
      this.afternoonIndex = ''
      this.double_nightIndex = ''
      this.double_afternoonIndex = ''
      this.double_contextmenuIndex = ''
    },
    // 单周透析新增
    async Dialysis_code(code, val) {
      console.log(val)
      let data = {
        week_id: this.week_id,
        patient_id: this.patient_id,
        schedule_type: val,
        dict_code: this.dict_code,
        dict_dialysis_type: code,
        week_index: this.week_index,
        week_count: 0,
      }
      let res = await service.post(
        '/api/patient_Schedule/create_patient_schedule',
        data
      )

      if (res.code === 0) {
        this.$message({
          message: '保存成功',
          type: 'success',
        })
        this.contextmenuIndex = ''
        this.nightIndex = ''
        this.afternoonIndex = ''
        this.double_nightIndex = ''
        this.double_afternoonIndex = ''
        this.double_contextmenuIndex = ''
        this.composing(this.patient_id)
      }
    },
    // 弹窗关闭
    closedialog() {
      this.contextmenuIndex = ''
      this.nightIndex = ''
      this.afternoonIndex = ''
    },
    // 单周晚上右键新增
    nightClick(index, val) {
      this.nightIndex = index
      this.dict_code = val
      this.week_index = Number(index)
    },
    //双周晚上右键新增
    double_nightClick(index, val) {
      this.double_nightIndex = index
      this.dict_code = val
      this.week_index = Number(index)
    },
    // 单周下午右键新增
    afternoonClick(index, val) {
      console.log(index)
      this.afternoonIndex = index
      this.dict_code = val
      this.week_index = Number(index)
    },
    // 双周下午右键新增
    double_afternoonClick(index, val) {
      this.double_afternoonIndex = index
      this.dict_code = val
      this.week_index = Number(index)
    },
  },
}
</script>

<style scoped>
* {
  font-size: 14px;
}

.TBclis {
  height: 54px;
  background: #ffffff;
  border-radius: 4px;
  opacity: 1;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.bdcli {
  /* display: flex; */
  height: 700px;
  overflow-y: scroll;
  background: #ffffff;
  border-radius: 4px;
  opacity: 1;
  padding: 10px;
  /* flex-wrap: wrap;
  align-content: flex-start; */
}

.zy-bord {
  height: 132px;
  background: #ffffff;
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  opacity: 1;
  padding: 10px;
  cursor: pointer;
  margin: 10px;
}

.tobys {
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  margin-top: 5px;
}

.zy-bord:hover {
  background: rgba(49, 102, 174, 1);
}

.zy-bord:hover div {
  color: #ffffff;
}

.zy-bord:hover span {
  color: #ffffff;
  background: #409eff;
}

.zy-age {
  width: 268px;
  height: 56px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #999999;
}

.bidsty {
  width: 64px;
  height: 24px;
  background: #ff3e3e;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  color: #ffffff;
  line-height: 24px;
  text-align: center;
  font-size: 12px;
}

.el-divider--horizontal {
  margin: 13px 0;
}

.botnmclis {
  height: 24px;
  background: rgba(49, 102, 174, 0.1);
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  font-size: 12px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  text-align: center;
  margin-right: 10px;
  line-height: 24px;
  padding: 0 15px;
}

div /deep/ .el-dialog__header {
  background-color: rgba(49, 102, 174, 0.1);
  border-radius: 10px !important;
  width: 100%;
}

div /deep/ .el-dialog__title {
  font-size: 24px;
  font-weight: 500;
  color: #000000;
}

div /deep/ .el-dialog {
  border-radius: 19px !important;
  padding: 20px 15px;
  margin: 10% auto !important;
}

div /deep/ .el-dialog__headerbtn {
  background-color: rgba(49, 102, 174, 0.3);
  border-radius: 20px !important;
  width: 18px;
  height: 17px;
  top: 41px !important;
  right: 37px !important;
}

.uins {
  height: 40px;
  background: #3166ae;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  display: flex;
  margin-top: 20px;
  line-height: 40px;
  color: #ffffff;
}

div /deep/ .el-dialog__body {
  padding: 10px 0;
}

.ZQclss {
  height: 32px;
  line-height: 32px;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.life-clis {
  width: 50%;
  height: 32px;
  background: rgba(255, 62, 62, 0.1);
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #ff3e3e;
  padding-left: 15px;
}

.icou1 {
  margin-top: 3px;
  font-size: 25px;
  color: rgba(49, 102, 174, 1);
  cursor: pointer;
}

.tab {
  height: 200px;
  display: flex;
  margin-bottom: 70px;
}

.tab1 {
  height: 40px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #ffffff;
  border-top: none;
  line-height: 40px;
}

.Tobu {
  height: 56px;
  line-height: 56px;
  background: rgba(49, 102, 174, 0.3);
  border: 1px solid #ffffff;
}

.RQclis {
  height: 40px;
  line-height: 40px;
  background: rgba(49, 102, 174, 0.3);
  display: flex;
  color: #3166ae;
  font-size: 14px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  text-align: center;
}

.ZQlis {
  width: 15%;
  border-right: 1px solid #ffffff;
}

.BOclis {
  height: 40px;
  line-height: 40px;
  background: #fff;
  display: flex;
  color: #444444;
  font-size: 14px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  text-align: center;
}

.BOlis {
  width: 15%;
  border: 1px solid #3166ae;
  border-left: none;
  border-top: none;
  overflow: hidden;
}

.BOlis:hover {
  background: rgba(49, 102, 174, 0.3);
  color: #fff;
}

.btncs {
  width: 88px;
  height: 32px;
  background: #3166ae;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  color: #fff;
  border: none;
  display: flex;
  line-height: 32px;
  cursor: pointer;
}

.icouis {
  font-size: 20px;
  color: #fff;
  margin: 2px 10px 0 13px;
}

.Cartop {
  font-size: 20px;
  color: #3166ae;
  cursor: pointer;
}

.Edrclis {
  font-size: 14px;
  color: rgba(255, 62, 62, 1);
  cursor: pointer;
}

.Edrcliss {
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  background-color: rgba(255, 62, 62, 1);
  border-radius: 20px;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 20px;
}

.cliss {
  height: 32px;
  background: rgba(255, 62, 62, 0.1);
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #ff3e3e;
  padding-left: 15px;
  line-height: 32px;
}

.m-2 {
  width: 100%;
}

.diailo {
  width: 1000px;
}

.radion {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  font-family: Source Han Sans SC-Regular, Source Han Sans SC;
  font-weight: 400;
  color: #444444;
}

.textclis {
  height: 100px;
  background: rgba(49, 102, 174, 0.1);
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  margin-top: 20px;
  padding: 15px;
  font-size: 14px;
}

.ZQclss_right {
  display: flex;
  justify-content: space-between;
  width: 83px;
}

.ZQclss_right_text {
  font-size: 14px;
  font-weight: 400;
  color: #3166ae;
  font-family: 'Source Han Sans CN-Regular', 'Source Han Sans CN';
}

.teb-left {
  width: 7%;
  background: rgba(49, 102, 174, 0.3);
  text-align: center;
  height: 215px;
}

.teb-left_text {
  height: 96px;
  line-height: 96px;
  border: 1px solid #ffffff;
}

.fa-times-circle {
  color: red;
  position: relative;
  font-size: 14px;
  right: 1px;
  cursor: pointer;
}

.botbtn {
  text-align: right;
}

.tabcliss {
  margin: 10px 0;
  cursor: pointer;
}

.tabcliss:hover {
  color: #3166ae;
}

.reference_text {
  width: 99%;
  text-align: center;
}

.el-input__icon {
  cursor: pointer;
}
</style>
